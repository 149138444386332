import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import DOMPurify from 'dompurify';
import axios from 'axios';
import loadingGif from '../assets/gfx/loading/loading.gif';

const FailPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [header, setHeader] = useState('');

    const handleBack = () => {
        navigate('/');
    };

    // useEffect(() => {
    //     setIsLoading(true);
    //     axios
    //         .get(`${process.env.REACT_APP_API_URL}/api/system_info`)
    //         .then((response) => {
    //             var obj = response.data.result[0];
    //             const gift_thankyou = DOMPurify.sanitize(obj?.gift_thankyou);
    //             setHeader(gift_thankyou);
    //             // openNotificationWithIconSuccess('success')
    //             setIsLoading(false);
    //         })
    //         .catch((error) => {
    //             console.log("An error occurred:", error.response);
    //             setIsLoading(false);
    //         });
    // }, []);
    return (
        <div style={{ maxWidth: 720, textAlign: 'center', margin: '50px auto' }}>
            <div className="CardBox TextCard" style={{ marginBottom: 5 }}>
                {/* {isLoading ? (
                    <div style={{ textAlign: 'center', margin: '20px' }}>
                        <img src={loadingGif} alt="Loading" style={{ width: '50px' }} />
                    </div>
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: header }}></div>
                )} */}
                <div>
                    Payment Failed.
                </div>
                <Button type="primary" onClick={handleBack}>
                    Regresar al inicio
                </Button>
                <div style={{ padding: '5px' }}></div>
            </div>
        </div>
    );
};

export default FailPage;
