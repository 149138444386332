import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainApp from './pages/MainAppPage';
import ThankYouPage from './pages/ThankyouPage';
import FailPage from './pages/FailPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainApp />} />
        <Route path="/success" element={<ThankYouPage />} />
        <Route path="/fail" element={<FailPage />} />
      </Routes>
    </Router>
  );
};

export default App;
