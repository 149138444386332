import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Button, Input, Col, Row, Card, notification, Radio } from 'antd';
import { UploadOutlined, ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import DOMPurify from 'dompurify';
import axios from 'axios';
import logo1 from '../assets/gfx/logos/logo1.png';
import logo2 from '../assets/gfx/logos/logo2.png';

import visaLogo from '../assets/images/visa.svg';
import mastercardLogo from '../assets/images/mastercard.svg';
import yappyLogo from '../assets/images/yappy.png';

import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber as isValidInternationalPhoneNumber } from 'react-phone-number-input';
import '../assets/css/MainAppPage.css';
import loadingGif from '../assets/gfx/loading/loading.gif';
import cardValidator from 'card-validator';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

const MainApp = () => {
  const navigate = useNavigate();
  const [selectedGiftCards, setSelectedGiftCards] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [giftCards, setGiftCards] = useState([]);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [header, setHeader] = useState('');
  const [footer, setFooter] = useState('');
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/gift/list`)
      .then((response) => {
        setGiftCards(response.data.result)
        // openNotificationWithIconSuccess('success')
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("An error occurred:", error.response);
        setIsLoading(false);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/system_info`)
      .then((response) => {
        var obj = response.data.result[0];
        const header = DOMPurify.sanitize(obj.gift_header);
        const footer = DOMPurify.sanitize(obj.gift_footer);
        setHeader(header);
        setFooter(footer);
        // openNotificationWithIconSuccess('success')
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("An error occurred:", error.response);
        setIsLoading(false);
      });

    // bank transfer
    const handlePasteGlobal = (e) => {
      if (dropZoneRef.current && dropZoneRef.current.contains(e.target)) {
        handlePaste(e);
      }
    };

    window.addEventListener('paste', handlePasteGlobal);

    return () => {
      window.removeEventListener('paste', handlePasteGlobal);
    };
  }, []);



  const submitData = () => {
    if (isSubmiting) return;

    if (!customerName || !email || !phoneNumber || !isEmailValid || !isPhoneValid) {
      openNotificationFillFormError('error', 'Por favor ingrese los datos para continuar.');
      return;
    }
    if (paymentMethod === '') {
      openNotificationFillFormError('error', 'Por favor seleccione Método de Pago.');
      return;
    }

    let jsonData = {
      gifts: selectedGiftCards,
      customer_name: customerName,
      customer_email: email,
      customer_phone: phoneNumber,
      total_amount: subtotal,
    };

    if (paymentMethod === "yappy_pay") {

      const formData = new FormData();
      jsonData = {
        ...jsonData,
        method_type: 1,
      }
      formData.append("bulkData", JSON.stringify([jsonData]));

      setIsSubmiting(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/gift/yappy`, formData)
        .then(response => {
          console.log('Success:', response.data.success);
          if (response.data.success) {
            window.location.href = response.data.url;
          } else {
            api.error({
              message: 'Oops there is an Error',
              description: ''
            });
          }
          setIsSubmiting(false);
        })
        .catch(error => {
          console.error('Error posting data:', error);
          let message = "Oops there is an Error";
          const { response } = error;
          if (response && response.status) {
            message = response.data && response.data.message;
          }
          api.error({
            message: 'Pago fallido',
            description: message
          });
          setIsSubmiting(false);
        });
    } if (paymentMethod === "card") {

      const cardNumberValidation = cardValidator.number(actualCardNumber);
      const expirationDateValidation = cardValidator.expirationDate(expireDate);
      const cvvValidation = cardValidator.cvv(cvv);


      if (!customerName || !expireDate || !actualCardNumber || !cvv) {
        openNotificationFillFormError('error', 'Por favor ingresa los detalles en Método de Pago.');
        return;
      }

      if (!cardNumberValidation.isValid || !expirationDateValidation.isValid || !cvvValidation.isValid) {
        api.error({
          message: 'Ha fallado la validación',
          description: 'Por favor verifique los datos de su tarjeta.',
        });
        return;
      }

      var firstName = getUserName(customerName)[0];
      var lastName = getUserName(customerName)[1] ? getUserName(customerName)[1] : firstName;
      if (lastName == '') lastName = firstName;
      jsonData = {
        ...jsonData,
        method_type: 2,
        cardInformation: {
          firstName,
          lastName,
          cardNumber: actualCardNumber,
          expMonth: expirationDateValidation.month,
          expYear: expirationDateValidation.year,
          cvv,
          cardType: "VISA"
        }
      }
      const formData = new FormData();
      formData.append("bulkData", JSON.stringify([jsonData]));
      setIsSubmiting(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/gift/purchase`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          console.log('Success:', response.data.success);
          if (response.data.success) {
            navigate('/success');
          } else {
            api.error({
              message: 'Oops there is an Error',
              description: ''
            });
          }
          setIsSubmiting(false);
        })
        .catch(error => {
          console.error('Error posting data:', error);
          let message = "Oops there is an Error";
          const { response } = error;
          if (response && response.status) {
            message = response.data && response.data.message;
          }
          api.error({
            message: 'Pago fallido',
            description: message
          });
          setIsSubmiting(false);
        });
    } else if (paymentMethod === "banktransfer") {

      if (!image) {
        openNotificationFillFormError('error', 'Por favor ingrese los datos para continuar.');
        return;
      }

      const file = image;
      jsonData = {
        ...jsonData,
        method_type: 3
      }
      const formData = new FormData();
      formData.append("bulkData", JSON.stringify([jsonData]));
      formData.append("_file", file);
      setIsSubmiting(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/gift/purchase`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          console.log('Success:', response.data.success);
          if (response.data.success) {
            navigate('/success');
          } else {
            api.error({
              message: 'Oops there is an Error',
              description: ''
            });
          }
          setIsSubmiting(false);
        })
        .catch(error => {
          console.error('Error posting data:', error);
          let message = "Oops there is an Error";
          const { response } = error;
          if (response && response.status) {
            message = response.data && response.data.message;
          }
          api.error({
            message: 'Pago fallido',
            description: message
          });
          setIsSubmiting(false);
        });
    }

  };


  const openNotificationWithIconError = (type) => {
    api[type]({
      message: 'Por favor seleccione un artículo antes de continuar.',
      description: '',
    });
  };

  const openNotificationFillFormError = (type, message) => {
    api[type]({
      message: message,
      description: '',
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const toggleGiftCard = (_id, gift_name, price) => {
    setSelectedGiftCards(prev => {
      const currentIndex = prev.findIndex(card => card._id === _id);
      const newSelection = [...prev];

      if (currentIndex === -1) {
        newSelection.push({ _id, gift_name, gift_price: price, count: 1 });
      } else {
        newSelection.splice(currentIndex, 1);
      }
      return newSelection;
    });
  };


  useEffect(() => {
    const newSubtotal = selectedGiftCards.reduce((acc, card) => acc + Number(card.gift_price), 0);
    setSubtotal(newSubtotal);
  }, [selectedGiftCards]);

  const handleButtonClick = () => {
    if (Object.keys(selectedGiftCards).length == 0) {
      openNotificationWithIconError('error')
    } else {
      setShowPaymentOptions(true);
    }
  };

  const handleCustomerNameChange = (event) => {
    setCustomerName(event.target.value);
  };

  const handleEmailChange = (event) => {
    const emailInput = event.target.value;
    setEmail(emailInput);
    setIsEmailValid(validateEmail(emailInput));
  };

  const validatePhoneNumber = (phoneNumber) => {
    if (!isValidInternationalPhoneNumber(phoneNumber)) {
      return false;
    }
    if (phoneNumber.startsWith('+507')) {
      const pattern = /^\+507(\d{7,8})$/;
      if (pattern.test(phoneNumber)) {
        const digits = phoneNumber.match(pattern)[1];
        const firstDigit = digits[0];
        const isValid = (firstDigit === '6' && digits.length === 8) || (firstDigit !== '6' && digits.length === 7);
        return isValid;
      }

      return false;
    }
    return true;
  };

  const handlePhoneNumberChange = (value) => {
    if (value == undefined) {
      value = '';
    }
    setPhoneNumber(value);
    const valid = validatePhoneNumber(value);
    setIsPhoneValid(valid);
  };


  const handlePaymentMethodChange = val => {
    setPaymentMethod(val);

    switch (val) {
      case "yappy_pay":
        break;
      case "card":
        break;
      case "banktransfer":
        setIsLoading(true);
        axios
          .get(`${process.env.REACT_APP_API_URL}/api/gift/bankinfo`)
          .then(response => {
            const cleanHTML = DOMPurify.sanitize(response.data.result[0].method_description);
            setBankInfo(cleanHTML);
            setIsLoading(false);
          })
          .catch(error => {
            setIsLoading(false);
            console.error('Failed to fetch bank info:', error);
            notification.error({
              message: 'Error Fetching Data',
              description: 'Could not fetch bank information. Please try again later.'
            });
          });
        break;
    }

  };



  // Credit Card 


  // const [payerName, setPayerName] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [displayCardNumber, setDisplayCardNumber] = useState('');
  const [actualCardNumber, setActualCardNumber] = useState('');

  const [isCardNumberValid, setIsCardNumberValid] = useState(true);
  const [isExpireDateValid, setIsExpireDateValid] = useState(true);
  const [isCvvValid, setIsCvvValid] = useState(true);

  const getUserName = (fullName) => {
    const splitName = fullName.split(' '); // Split the string by space
    return splitName.slice(0, 2); // Get the first two elements
  }

  const handleExpireDateChange = (e) => {

    const inputValue = e.target.value;
    let cleanedExpireDate = inputValue.replace(/\D/g, '');

    // Limit the card number to 16 digits
    if (cleanedExpireDate.length > 4) {
      cleanedExpireDate = cleanedExpireDate.slice(0, 4);
    }
    // setExpireDate(cleanedExpireDate);
    const formattedExpireDate = cleanedExpireDate.replace(/(\d{2})(\d{2})/, '$1/$2');
    setExpireDate(formattedExpireDate);

    const expirationDateValidation = cardValidator.expirationDate(cleanedExpireDate);
    setIsExpireDateValid(expirationDateValidation.isValid)
  }

  const handleCvvChange = (e) => {

    const inputValue = e.target.value;
    let cleanedValue = inputValue.replace(/\D/g, '');

    if (cleanedValue.length > 3) {
      cleanedValue = cleanedValue.slice(0, 3);
    }
    setCvv(cleanedValue);
    const cvvValidation = cardValidator.cvv(cleanedValue);
    setIsCvvValid(cvvValidation.isValid)
  }

  const handleCardNumberChange = (e) => {
    let cardNumber = e.target.value;
    let cleanedNumber = cardNumber.replace(/\D/g, '');

    if (cleanedNumber.length > 16) {
      cleanedNumber = cleanedNumber.slice(0, 16);
    }

    // Format with spaces for display
    const formattedNumber = cleanedNumber.replace(/(.{4})/g, '$1 ').trim();

    setDisplayCardNumber(formattedNumber); // State used for display in the input
    setActualCardNumber(cleanedNumber); // State used for the actual data processing


    const cardNumberValidation = cardValidator.number(cleanedNumber);
    setIsCardNumberValid(cardNumberValidation.isValid);
  };


  /// BankTransfer


  const [image, setImage] = useState(null);
  const [bankInfo, setBankInfo] = useState('');
  const fileInputRef = useRef(null);
  const dropZoneRef = useRef(null);


  const handleFileChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const handlePaste = useCallback((e) => {
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    for (const item of items) {
      if (item.kind === 'file' && item.type.startsWith('image/')) {
        const blob = item.getAsFile();
        setImage(URL.createObjectURL(blob));
      }
    }
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const handleImgButtonClick = () => {
    fileInputRef.current.click();
  };

  const clearImage = () => {
    setImage(null);
  };

  return (
    <>
      {contextHolder}
      <div style={{ maxWidth: 720, margin: '0 auto', backgroundColor: '#f0f2f5' }}>
        <div className="CardBox TextCard" style={{ marginBottom: 5 }}>
          <Row gutter={16} justify="space-between" align="middle">
            <Col span={12}>
              <img src={logo1} alt='Logo1' style={{ width: '25vh' }} />
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <img src={logo2} alt='Logo2' style={{ width: '7vh' }} />
            </Col>
          </Row>
        </div>
        {!showPaymentOptions && (
          <>
            {isLoading ? (
              <div className='TextCard' style={{ marginBottom: 5 }}>
                <div style={{ textAlign: 'center', margin: '20px' }}>
                  <img src={loadingGif} alt="Loading" style={{ width: '50px' }} />
                </div>
              </div>
            ) : (
              <>
                <div className='TextCard' style={{ marginBottom: 5 }}>
                  <div dangerouslySetInnerHTML={{ __html: header }}></div>
                </div>
                <div className="CardBox TextCard" style={{ marginBottom: 5, paddingTop: 16, paddingBottom: 16 }}>

                  <>
                    {giftCards.map((card) => (
                      <Row key={card._id} justify="space-between" align="middle">
                        <Col span={16}>
                          <Checkbox
                            onChange={() => toggleGiftCard(card._id, card.gift_name, card.gift_price)}
                            checked={selectedGiftCards.some(item => item._id === card._id)}
                          >
                            {card.gift_name}
                          </Checkbox>
                        </Col>
                        <Col span={8} style={{ textAlign: 'right' }}>
                          ${Number(card.gift_price).toFixed(2)}
                        </Col>
                      </Row>
                    ))}
                  </>
                </div>
                <div className='TextCard' style={{ marginBottom: 5 }}>
                  <div dangerouslySetInnerHTML={{ __html: footer }}></div>
                </div>
                <div className="CardBox TextCard" style={{ marginBottom: 5 }}>
                  <Row gutter={16} justify="space-between" align="middle" style={{ marginBottom: 5 }}>
                    <Col>
                      <h3>Subtotal:</h3>
                    </Col>
                    <Col>
                      <h3>${subtotal.toFixed(2)}</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Button type="primary" block style={{ backgroundColor: '#157DB1', marginBottom: 15 }} onClick={handleButtonClick}>
                        Siguiente
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </>
        )}
        {showPaymentOptions && (
          <>
            <div className="CardBox TextCard" style={{ marginBottom: 5 }}>
              {selectedGiftCards.map((card) => (
                <Row key={card.id} justify="space-between" align="middle">
                  <Col span={16}>
                    <b>{card.gift_name}</b>
                  </Col>
                  <Col span={8} style={{ textAlign: 'right' }}>
                    <b>${Number(card.gift_price).toFixed(2)}</b>
                  </Col>
                </Row>
              ))}
              <div style={{ marginTop: 5 }}>
                <span style={{ fontSize: '14px' }}><a href='/'>Actualizar</a></span>
              </div>
            </div>
            <div className="CardBox TextCard" style={{ marginBottom: 5 }}>
              <Row gutter={16}>
                <Col span={24} style={{ marginBottom: 10 }}>
                  <label>Nombre y apellido</label>
                  <Input
                    style={{ marginTop: 5 }}
                    placeholder="Ingrese su nombre y apellido"
                    value={customerName}
                    onChange={handleCustomerNameChange}
                  />
                </Col>
                <Col span={24} style={{ marginBottom: 10 }}>
                  <label>Email</label>
                  <Input
                    style={{ marginTop: 5 }}
                    placeholder="Ingrese su email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  {!isEmailValid && <div style={{ color: 'red' }}>El email es inválido.</div>}
                </Col>
                <Col span={24} style={{ marginBottom: 5 }}>
                  <label>Teléfono</label>
                  <PhoneInput
                    style={{ marginTop: 5 }}
                    international
                    defaultCountry="PA"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    placeholder="Ingrese su teléfono"
                  />
                  {!isPhoneValid && <div style={{ color: 'red' }}>El teléfono es inválido.</div>}
                </Col>
              </Row>
            </div>
            <div className="CardBox TextCard" style={{ marginBottom: 5 }}>
              <h3 style={{ marginBottom: 5 }}>Pago</h3>
              <span style={{ fontSize: '14px' }}>Todas las transacciones son seguras y están encriptadas.</span>
              <p />
              <Accordion onChange={(val) => handlePaymentMethodChange(val[0])}>
                <AccordionItem uuid="yappy_pay">
                  <AccordionItemHeading>
                    <AccordionItemButton className={"custom-heading" + (paymentMethod === 'yappy_pay' ? " selected " : "")} onClick={() => handlePaymentMethodChange('yappy_pay')}>
                      <Row gutter={16} justify="space-between" align="middle">
                        <label>
                          <input type="radio" className="accordion-radio" value="yappy_pay"
                            checked={paymentMethod === 'yappy_pay'} onChange={() => handlePaymentMethodChange('yappy_pay')}
                          />
                          Yappy
                        </label>
                        <img src={yappyLogo} alt='Logo2' style={{ height: '25px' }} />
                      </Row>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  {/* <AccordionItemPanel className="custom-panel">
                    Buscar "latapadelcoco" en el directorio de YAPPY<br />
                    Todo pegado sin espacios, si tiene dudas escribir al +507 6391-1319 (servicio al cliente)
                  </AccordionItemPanel> */}
                </AccordionItem>
                {/* <AccordionItem uuid="card">
                  <AccordionItemHeading>
                    <AccordionItemButton className={"custom-heading" + (paymentMethod === 'card' ? " selected " : "")}>
                      <Row gutter={16} justify="space-between" align="middle">
                        <label>
                          <input type="radio" className="accordion-radio" value="card"
                            checked={paymentMethod === 'card'} onChange={() => handlePaymentMethodChange('card')}
                          />
                          Tarjeta de Crédito
                        </label>
                        <label>
                          <img src={visaLogo} alt='Card' style={{ height: '25px' }} /> &nbsp;
                          <img src={mastercardLogo} alt='Card' style={{ height: '25px' }} />
                        </label>
                      </Row>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="custom-panel">
                    <Row gutter={16}>
                      <Col span={24} style={{ marginBottom: 10 }}>
                        <label>Card Number</label>
                        <Input
                          style={{ marginTop: 5 }}
                          placeholder="Card Number"
                          value={displayCardNumber}
                          onChange={handleCardNumberChange}
                          status={!isCardNumberValid ? 'error' : ''}
                        />
                      </Col>
                      <Col span={12} style={{ marginBottom: 10 }}>
                        <label>Expire Date</label>
                        <Input
                          style={{ marginTop: 5 }}
                          placeholder="Expire Date"
                          value={expireDate}
                          onChange={handleExpireDateChange}
                          status={!isExpireDateValid ? 'error' : ''}
                        />
                      </Col>
                      <Col span={12} style={{ marginBottom: 10 }}>
                        <label>Cvv</label>
                        <Input
                          style={{ marginTop: 5 }}
                          placeholder="Cvv"
                          value={cvv}
                          onChange={handleCvvChange}
                          status={!isCvvValid ? 'error' : ''}
                        />
                      </Col>
                    </Row>
                  </AccordionItemPanel>
                </AccordionItem> */}
                <AccordionItem uuid="banktransfer">
                  <AccordionItemHeading>
                    <AccordionItemButton className={"custom-heading" + (paymentMethod === 'banktransfer' ? " selected " : "")}>
                      <Row gutter={16} justify="space-between" align="middle">
                        <label>
                          <input type="radio" className="accordion-radio" value="banktransfer"
                            checked={paymentMethod === 'banktransfer'} onChange={() => handlePaymentMethodChange('banktransfer')}
                          />
                          Transferencia
                        </label>
                      </Row>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="custom-panel">
                    {isLoading ? (
                      <div style={{ textAlign: 'center', margin: '20px' }}>
                        <img src={loadingGif} alt="Loading" style={{ width: '50px' }} />
                      </div>
                    ) : (
                      <div dangerouslySetInnerHTML={{ __html: bankInfo }}></div>
                    )}
                    <hr style={{ marginBottom: 24, opacity: 0.5 }} />
                    <Row justify="center" align="middle" style={{ marginBottom: 24 }}>
                      <div style={{ padding: "5px 10px" }}>Por favor suba el comprobante de la transacción</div>
                      <div
                        className='proof'
                        style={{
                          height: '25vh',
                          width: '35vh',
                          border: '3px dashed grey',
                          textAlign: 'center',
                          // backgroundColor: '#c9dadf',
                          backgroundImage: `url(${image})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          position: 'relative',
                        }}
                        ref={dropZoneRef}
                        onDrop={handleDrop}
                        onDragOver={(e) => e.preventDefault()}
                        onClick={handleImgButtonClick}
                      >
                        {!image && (
                          <div style={{ justifyContent: 'center', top: '30%', position: 'relative' }}>
                            <div>
                              <UploadOutlined style={{ fontSize: '60px', opacity: 0.5 }}></UploadOutlined>
                            </div>
                            <div>
                              <span style={{ fontSize: '16px' }}><b style={{ cursor: 'pointer' }}>Seleccione</b> una foto o archivo</span>
                            </div>
                            <input
                              ref={fileInputRef}
                              type="file"
                              accept="image/*"
                              style={{ display: 'none' }}
                              onChange={handleFileChange}
                            />
                          </div>
                        )}
                        {image && <Button type="primary" onClick={clearImage} style={{ position: 'absolute', top: 0, right: 0 }}><b>REMOVER</b></Button>}
                      </div>
                    </Row>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <Row gutter={16} justify="space-between" align="middle">
                <Col>
                  <h3>Subtotal:</h3>
                </Col>
                <Col>
                  <h3>${subtotal.toFixed(2)}</h3>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Button type="primary" block style={{ backgroundColor: '#157DB1', marginBottom: 15 }} onClick={submitData}>
                    {
                      isSubmiting ?
                        <LoadingOutlined />
                        :
                        "Siguiente"
                    }
                  </Button>
                </Col>

              </Row>
              <div id='Yappy_Checkout_Button'></div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MainApp;